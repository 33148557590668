import { ActionIcon, Group, Paper, SimpleGrid, Tooltip, Collapse } from "@mantine/core";
import { IconChevronDown, IconChevronUp, IconStar, IconTool } from "@tabler/icons-react";
import { useState } from "react";
import { openRoomIssueModal, openRoomRateModal } from "../../../helpers/modals.js";
import PointsSummary from "./PointsSummary.jsx";
import dayjs from "dayjs";
import { useUser } from "../../../providers/UserProvider.jsx";

export default function RoomGroup({ group }) {
  const [opened, setOpened] = useState(false);
  const day = dayjs().day();
  const { user } = useUser();

  return (
    <>
      <Paper p="sm" className="mb-4 cursor-pointer" withBorder radius="md" onClick={() => setOpened(!opened)}>
        <Group justify="space-between">
          <h2>{group.name}</h2>
          {opened ? <IconChevronUp stroke={1.5} /> : <IconChevronDown stroke={1.5} />}
        </Group>
      </Paper>
      <Collapse in={opened}>
        <SimpleGrid spacing={8} cols={2} className="mb-5">
          {group.rooms.map((room) => {
            return (
              <Paper p="sm" radius="md" withBorder key={room.id}>
                <Group justify="space-between">
                  <strong>{room.name}</strong>

                  <PointsSummary room={room} />

                  <strong>{room.pointsTotal} bodů</strong>

                  {(user.permissions.includes("accommodation.rooms.issues.store") ||
                    user.permissions.includes("accommodation.points.store")) && (
                    <div className="flex items-center gap-2">
                      {user.permissions.includes("accommodation.rooms.issues.store") && (
                        <Tooltip
                          position="top"
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label="Přidání škody">
                          <ActionIcon
                            variant="light"
                            color="gray"
                            onClick={(e) => {
                              e.stopPropagation();
                              openRoomIssueModal(`Přidání škody`, room, room.name);
                            }}>
                            <IconTool stroke={1.5} size={16} />
                          </ActionIcon>
                        </Tooltip>
                      )}
                      {user.permissions.includes("accommodation.points.store") && (
                        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Bodovat">
                          <ActionIcon
                            variant={room.points[day] ? "light" : "filled"}
                            color={room.points[day] ? "gray" : undefined}
                            onClick={(e) => {
                              e.stopPropagation();
                              openRoomRateModal(`Bodování pokoje - ${room.name}`, room, day);
                            }}>
                            <IconStar stroke={1.5} size={16} />
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </Group>
              </Paper>
            );
          })}
        </SimpleGrid>
      </Collapse>
    </>
  );
}
