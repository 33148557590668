import { ActionIcon, Card, Collapse, Group, Paper, SimpleGrid, Text } from "@mantine/core";
import {
  IconChevronDown,
  IconChevronUp,
  IconEdit,
  IconMars,
  IconMedicalCross,
  IconTool,
  IconUsers,
  IconVenus,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { openRoomEditModal, openRoomIssueModal } from "../../../helpers/modals.js";
import { getMode, roomType } from "../../../helpers/helpers.js";
import classNames from "classnames";
import { useUser } from "../../../providers/UserProvider.jsx";

export default function RoomGroup({ group, opened, search }) {
  const [openedLocal, setOpenedLocal] = useState(opened || false);
  const [rooms, setRooms] = useState(group.rooms);
  const { user } = useUser();

  const { mode } = getMode();

  useEffect(() => {
    if (search === "") {
      setOpenedLocal(false);
      setRooms(group.rooms);
      return;
    }
    setRooms(group.rooms.filter((room) => room.name.toLowerCase().includes(search.toLowerCase())));
    if (rooms.length > 0) {
      setOpenedLocal(true);
    } else {
      setOpenedLocal(false);
    }
  }, [search]);

  return (
    <>
      <Paper p="sm" className="mb-4 cursor-pointer" withBorder radius="md" onClick={() => setOpenedLocal(!openedLocal)}>
        <Group justify="space-between">
          <h2>{group.name}</h2>
          <div className="flex items-center gap-4">
            <Text c="dimmed">
              {group?.rooms
                .filter((room) => room.type === "room")
                .reduce((accumulator, object) => {
                  return accumulator + object.capacity;
                }, 0)}{" "}
              míst celkem
            </Text>
            {openedLocal ? <IconChevronUp stroke={1.5} /> : <IconChevronDown stroke={1.5} />}
          </div>
        </Group>
      </Paper>
      <Collapse in={openedLocal}>
        <SimpleGrid cols={2} className="mb-5" spacing="xs">
          {rooms.map((room) => {
            if (!room.name.toLowerCase().includes(search.toLowerCase())) {
              return null;
            }
            return (
              <Paper p={0} radius="md" className={classNames("relative overflow-hidden")} withBorder key={room.id}>
                <div className="flex">
                  <div
                    className={classNames("flex h-12 w-12 items-center justify-center", {
                      "bg-pink-200": room.roomGender === "Dívka" && room.type === "room" && mode === "dark",
                      "bg-cyan-200": room.roomGender === "Chlapec" && room.type === "room" && mode === "dark",
                      "bg-pink-500": room.roomGender === "Dívka" && room.type === "room" && mode === "light",
                      "bg-cyan-500": room.roomGender === "Chlapec" && room.type === "room" && mode === "light",
                      "bg-yellow-200": room.type === "staff" && mode === "dark",
                      "bg-yellow-400": room.type === "staff" && mode === "light",
                      "bg-red-100": room.type === "sickroom" && mode === "dark",
                      "bg-red-500": room.type === "sickroom" && mode === "light",
                      "bg-dark-300": !room.roomGender && mode === "dark",
                      "bg-gray-600": !room.roomGender && mode === "light",
                    })}>
                    {room.roomGender === "Dívka" && room.type === "room" && <IconVenus stroke={2} size={28} />}
                    {room.roomGender === "Chlapec" && room.type === "room" && <IconMars stroke={2} size={28} />}
                    {room.type === "sickroom" && <IconMedicalCross stroke={2} size={28} />}
                    {room.type === "staff" && <IconUsers stroke={2} size={28} />}
                  </div>
                  <Group justify="space-between" className="grow px-4 py-2">
                    <strong>{room.name}</strong>
                    <Text>
                      {room.occupationCount}/{room.capacity}
                    </Text>

                    <Text>{roomType(room.type)}</Text>
                    <div className="flex items-center gap-2">
                      <ActionIcon
                        variant="light"
                        color="gray"
                        onClick={(e) => {
                          e.stopPropagation();
                          openRoomIssueModal(`Přidání škody`, room, room.name);
                        }}>
                        <IconTool stroke={1.5} size={16} />
                      </ActionIcon>
                      {user.permissions.includes("accommodation.rooms.update") && (
                        <ActionIcon
                          variant="light"
                          color="gray"
                          onClick={(e) => {
                            e.stopPropagation();
                            openRoomEditModal(`Úprava pokoje`, room, room.name);
                          }}>
                          <IconEdit stroke={1.5} size={16} />
                        </ActionIcon>
                      )}
                    </div>
                  </Group>
                </div>
              </Paper>
            );
          })}
        </SimpleGrid>
      </Collapse>
    </>
  );
}
