import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconUsers } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import Table from "./core/Table.jsx";
import { openTeamEditModal } from "../../helpers/modals.js";
import { sortTeams } from "./sort.js";
import { useUser } from "../../providers/UserProvider.jsx";

export default function TeamsManageTable({ teams, staff, refreshing }) {
  // const [sortStatus, setSortStatus] = useState({ columnAccessor: 'name', direction: 'asc' });
  //
  // useEffect(() => {
  //     const data = sortTeams(sortStatus, records);
  //     setRecords(data);
  // }, [sortStatus]);
  //
  // useEffect(() => {
  //     setRecords(sortTeams(sortStatus, teams))
  // }, [teams])
  const navigate = useNavigate();
  const { user } = useUser();

  const teamName = (team) => {
    return (
      <>
        {team.name} #{team.number}
        <Text span fw={400}>
          {team.customName ? " (" + team.customName + ")" : ""}
        </Text>
      </>
    );
  };

  const leaderName = (team) => {
    return (
      <div>
        {team.leader ? team.leader.firstName + " " + team.leader.lastName : <Text color="red">Nenastaven</Text>}
      </div>
    );
  };

  const childrenCount = (team) => {
    return <div>{team.childrenCount} dětí</div>;
  };

  const actionButtons = (team, teams, staff) => {
    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Seznam dětí">
          <Link
            to={"/oddily/" + team.id}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <ActionIcon variant="light" color="gray">
              <IconUsers stroke={1.5} size={18} />
            </ActionIcon>
          </Link>
        </Tooltip>

        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Upravit oddíl">
          <ActionIcon
            variant="light"
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              openTeamEditModal(`Úprava oddílu – ${team?.name} #${team?.number}`, team, teams, staff);
            }}>
            <IconEdit stroke={1.5} size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (team) => teamName(team),
      sortable: true,
      sortValue: (team) => " " + team.number, //todo xd
    },
    {
      accessor: "childrenCount",
      title: "Počet",
      render: (team) => childrenCount(team),
      visibleMediaQuery: (theme) => `(min-width: 375px)`,
      sortable: true,
      sortValue: (team) => team.childrenCount,
    },
    {
      accessor: "leader",
      title: "Vedoucí",
      render: (team) => leaderName(team),
      visibleMediaQuery: (theme) => `(min-width: 500px)`,
      sortable: true,
      sortValue: (team) => team.leader?.firstName + " " + team.leader?.lastName,
    },
    ...(!user.permissions.includes("teams.update")
      ? []
      : [
          {
            accessor: "actions",
            title: "Akce",
            disableHiding: true,
            render: (team) => actionButtons(team, teams, staff),
            narrow: true,
          },
        ]),
  ];

  return (
    <Table
      columns={columns}
      id="oddil-uprava"
      records={teams}
      sortFunction={sortTeams}
      onRowClick={(team) => {
        if (user.permissions.includes("teams.show")) {
          navigate("/oddily/" + team.id);
        }
      }}
    />
  );
}
